import { Layout } from 'antd'
import { screenSize } from 'src/theme/screenSize'
import { spaces } from 'src/theme/spaces'
import styled from 'styled-components'

export const LayoutWrapper = styled(Layout)`
  min-height: 100vh;
  padding: ${spaces.space80};
  display: flex;
  align-items: center;

  @media (max-width: ${screenSize.sm}) {
    padding: ${spaces.base};
  }
`

export const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  @media (max-width: ${screenSize.sm}) {
    max-width: 500px;
  }
`
