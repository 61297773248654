import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'

import { authLink } from './authLink'
import { errorLink } from './errorLink'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_ENDPOINT,
})

const consoleLink = new ApolloLink((operation, forward) => {
  console.log(`starting request for ${operation.operationName}`)
  return forward(operation).map((data) => {
    console.log(`ending request for ${operation.operationName}`)
    return data
  })
})

export const client = new ApolloClient({
  link: from([errorLink, authLink, consoleLink, httpLink]),
  cache: new InMemoryCache(),
})
