import React from 'react'
import { useLocation } from 'react-router-dom'
import { PATH } from 'src/constants/common'
import AdminLayout from './admin/layout/Layout'
import UserLayout from './user/layout/Layout'

export default function AppLayout({ children }: any) {
  const location = useLocation()
  const ignoreAppLayout = [PATH.ADMIN_LOGIN, PATH.ADMIN_HOME]

  const returnComponent = () => {
    if (ignoreAppLayout.includes(location.pathname)) {
      return <>{children}</>
    } else if (location.pathname.includes(PATH.ADMIN_HOME)) {
      if (location.pathname === PATH.ADMIN_SCAN) {
        return children
      }
      return <AdminLayout>{children}</AdminLayout>
    } else if (location.pathname.includes(PATH.HOME)) {
      return <UserLayout>{children}</UserLayout>
    } else {
      return <>{children}</>
    }
  }

  return returnComponent()
}
