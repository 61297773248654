import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PublicRoutes, AdminPrivateRoutes } from './configs/Router'
import AppLayout from './features/AppLayout'
import NotFoundPage from './pages/NotFoundPage'

function App() {
  return (
    <BrowserRouter>
      <AppLayout>
        <Suspense fallback={<>{'ローディング…'}</>}>
          <Routes>
            {PublicRoutes}
            {AdminPrivateRoutes}
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </AppLayout>
    </BrowserRouter>
  )
}

export default App
