import { Avatar, Button, Input, Layout, Menu, Select } from 'antd'
import { spaces } from 'src/theme/spaces'
import { theme } from 'src/theme/theme'
import styled from 'styled-components'
import { fontSizes } from '../../../theme/fontSizes'
export const { Header, Sider, Content } = Layout

export const LayoutWrapper = styled(Layout)`
  min-height: 100vh;
  position: relative;
  min-width: 1366px;
  .ant-layout-sider {
    background-color: #fff;
  }
  .ant-layout-sider-trigger {
    background-color: #fff;
    color: ${theme.colorPrimary};
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0;
  }
  .ant-menu-inline {
    padding: ${spaces.base} 0;
  }
`
export const CustomHeader = styled(Header)`
  padding: 0 20px !important;
  background-color: ${theme.colorPrimary} !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CustomSider = styled(Sider)`
  position: relative;
`

export const CustomContent = styled(Content)`
  padding: ${spaces.base};
  height: 100%;
  max-height: calc(100vh - 64px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 0px;
  }
`

export const ButtonCollapse = styled(Button)`
  width: 32px;
  height: 32px;
  font-weight: bold;
  color: ${theme.colorPrimary};
  padding: 0;
  position: absolute;
  top: 14px;
  right: -16px;
`

export const ProfileWrapper = styled.div`
  /* cursor: pointer; */
`

export const CustomAvatar = styled(Avatar)`
  margin-left: 0.5rem;
`
export const CustomName = styled.div`
  font-size: ${fontSizes.base};
  font-weight: 500;
  color: #fff;
`

export const MenuItem = styled(Menu.Item)`
  padding: 0.6rem 1.5rem !important;
`

export const ActionWraper = styled.div`
  display: flex;
  align-items: center;
`

export const CustomInputSearch = styled(Input.Search)`
  display: flex;
  align-items: center;
  width: 250px;
`

export const CustomSelect = styled(Select)`
  width: 250px;
`

export const ContentWrapper = styled.div`
  /* padding: ${spaces.base}; */
  min-height: 200px;
  background-color: white;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 0px;
  overflow-x: hidden;
`
export const ButtonCancel = styled(Button)`
  width: 50%;
  margin-right: 5px;
`

export const ButtonSubmit = styled(Button)`
  width: 50%;
  margin-left: 5px;
`

export const WrapperInputSearch = styled.div`
  margin-right: 0.5rem;
  display: inline-block;
`

export const ActionItem = styled.button`
  line-height: normal !important;
  margin-right: 0.5rem;
  padding: 5px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #ffffff;
  &:disabled {
    background-color: #ddd;
    cursor: no-drop;
    & > div {
      color: gray !important;
    }
  }
  &:disabled:hover {
    border: 1px solid #ddd;
    & > div {
      color: gray !important;
    }
  }

  &.create:disabled:hover {
    border: 1px solid #ddd;
    & > div {
      color: gray !important;
    }
    filter: none !important;
  }

  &.edit:disabled:hover {
    border: 1px solid #ddd;
    & > div {
      color: gray !important;
    }
    filter: none !important;
  }

  &.delete:disabled:hover {
    border: 1px solid #ddd;
    & > div {
      color: gray !important;
    }
    filter: none !important;
  }

  &.create:hover {
    border: 1px solid green;
    filter: drop-shadow(0 0 0.5rem green);
  }

  &.edit:hover {
    border: 1px solid #4081ec;
    filter: drop-shadow(0 0 0.5rem #4081ec);
  }

  &.delete:hover {
    border: 1px solid red;
    filter: drop-shadow(0 0 0.5rem red);
  }

  div {
    font-size: 1.2rem;
  }
`

export const LogoWrapper = styled.div`
  max-width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  background-color: ${theme.colorPrimary};
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

export const FormTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 22px;
    margin-bottom: ${theme.spaces.space00};
    font-weight: bold;
    text-align: center;
    margin-bottom: 0px;
    color: #fff;
    line-height: 22px;
    margin-bottom: 4px;
    max-height: 30px;
    transition: all 0.3s;
  }

  span {
    font-size: 13px;
    margin-bottom: ${theme.spaces.space00};
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px;
    color: #fff;
    max-height: 20px;
    transition: all 0.3s;
  }

  &.header-title--close {
    span {
      font-size: 5px;
    }

    p {
      font-size: 6px;
      line-height: 6px;
    }
  }
`

export const TitleHeader = styled.div`
  font-size: ${fontSizes.large};
  color: #fff;
  font-weight: bold;
  margin-left: ${spaces.base};
  position: relative;

  &.title-header-close {
    left: -85px;
  }

  &.title-header-open {
    left: -215px;
  }
`

export const LogoutButton = styled.div`
  padding: 0px 8px;
  margin-top: auto;
  position: absolute;
  bottom: 20px;
  left: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }
`
