import { spaces } from './spaces'

export const theme = {
  sizes: {
    XS: '0.6rem',
    S: '0.8rem',
    M: '1rem',
    L: '1.2rem',
    XL: '1.4rem',
    XXL: '1.6rem',
  },
  spaces: spaces,
  colorPrimary: '#6f42c1',
  boxShadow: '0 0 10px -5px gray',
  borderRadius: 10,
  headerHeight: 60,
  sideBarWidth: 250,
  checkBoxWidth: 70,
  textColWidth: 300,
  orderColWidth: 110,
  colorColWidth: 125,
  fontSizeWidth: 110,
  normalColWidth: 200,
}
