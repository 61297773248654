import { lazy } from 'react'
import { Navigate, Route, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../appRedux/hooks'
import { PATH, ROUTES } from '../constants/common'
import {
  selectIsAdminLoggedIn,
  selectPathNameHisoty,
  setPathNameHistory,
} from '../features/admin/login/adminLoginSlice'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLoggedInAdmin = useAppSelector(selectIsAdminLoggedIn)
  const location = useLocation()
  const dispatch = useAppDispatch()

  if (location.pathname?.includes(PATH.ADMIN_HOME) && !isLoggedInAdmin) {
    dispatch(setPathNameHistory(location.pathname))
    return <Navigate to={PATH.ADMIN_LOGIN} state={{ from: location }} replace />
  }

  if (location.pathname === PATH.ADMIN_HOME && isLoggedInAdmin) {
    return (
      <Navigate to={PATH.ADMIN_DASHBOARD} state={{ from: location }} replace />
    )
  }

  return children
}

const DefaultRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const isLoggedInAdmin = useAppSelector(selectIsAdminLoggedIn)
  const PathNameHisoty = useAppSelector(selectPathNameHisoty)

  if (
    location.pathname === '/' ||
    location.pathname === PATH.HOME ||
    location.pathname === PATH.BASE_REGISTER
  ) {
    return <Navigate to={PATH.REGISTER} state={{ from: location }} replace />
  }

  if (location.pathname === PATH.ADMIN_LOGIN && isLoggedInAdmin) {
    if (PathNameHisoty === '') {
      console.log(PathNameHisoty, 'PathNameHisoty')
      return (
        <Navigate
          to={PATH.ADMIN_DASHBOARD}
          state={{ from: location }}
          replace
        />
      )
    }

    return <Navigate to={PathNameHisoty} state={{ from: location }} replace />
  }

  return children
}

export const PublicRoutes: React.ReactElement[] | null = ROUTES.PUBLIC.map(
  (route) => {
    const Component = lazy(() => import(`../pages/${route.element}`))

    return (
      <Route
        {...route}
        element={
          <DefaultRoute>
            <Component />
          </DefaultRoute>
        }
        key={route.element}
      />
    )
  },
)

export const AdminPrivateRoutes: React.ReactElement[] | null =
  ROUTES.ADMIN_PRIVATE.map((route) => {
    const Component = lazy(() => import(`../pages/${route.element}`))
    return (
      <Route
        {...route}
        element={
          <RequireAuth>
            <Component />
          </RequireAuth>
        }
        key={route.element}
      />
    )
  })
