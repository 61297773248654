import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { notification } from 'antd'
import { IconType } from 'antd/es/notification/interface'
import styled from 'styled-components'

type NotificationType = {
  type: IconType
  message?: string
  description?: string
  duration?: number | null
  key?: string
}

const colors = {
  success: '#549d57',
  error: '#e91a2f',
  warning: '#dbaa19',
  info: '#219cd4',
}

const backgrounds = {
  success: '#fff',
  error: '#fff',
  warning: '#fff',
  info: '#fff',
}

const texts = {
  success: '成功',
  error: 'エラー',
  warning: '警告',
  info: '通知',
}

export const WrapperDescription = styled.div`
  display: flex;
  align-items: center;
  .emphasize-number {
    font-size: 18px;
  }
`

export const Notification = (values: NotificationType) => {
  const { type, description, duration, key } = values

  const generalStyle = {
    borderRadius: '5px',
    color: colors[type],
    borderLeft: `6px solid ${colors[type]}`,
    backgroundColor: backgrounds[type],
  }

  const getNotificationStyle = (type: IconType) => {
    return {
      [type]: {
        ...generalStyle,
      },
    }[type]
  }

  const getNotificationIcon = (type: IconType) => {
    return {
      success: <CheckOutlined />,
      warning: <WarningOutlined />,
      error: <CloseOutlined />,
      info: <ExclamationCircleOutlined />,
    }[type]
  }

  const getNotificationMessage = (type: IconType) => {
    return {
      [type]: (
        <div style={{ fontWeight: 'bold', color: colors[type] }}>
          {texts[type]}
        </div>
      ),
    }[type]
  }

  return notification[type]({
    key,
    message: getNotificationMessage(type),
    description: (
      <WrapperDescription
        dangerouslySetInnerHTML={{
          __html: description || '',
        }}
      />
    ),
    duration,
    style: getNotificationStyle(type),
    icon: getNotificationIcon(type),
  })
}
