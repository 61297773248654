import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../../appRedux/store'
import Cookies from 'js-cookie'
import { COOKIE_ITEM, STORAGE_ITEM } from '../../../constants/common'

// Define a type for the slice state
type AdminLoginState = {
  isAdminLoggedIn: boolean
  adminInfo?: {
    loginId?: string
    access_token?: string
    nickname?: string
  }
  pathnameHistory: string
}

// Define the initial state using that type
const initialState: AdminLoginState = {
  isAdminLoggedIn: !!Cookies.get(COOKIE_ITEM.ADMIN_TOKEN) || false,
  adminInfo:
    typeof window !== 'undefined'
      ? localStorage.getItem(STORAGE_ITEM.USER_INFO)
        ? JSON.parse(localStorage.getItem(STORAGE_ITEM.USER_INFO) || '{}')
        : JSON.parse(sessionStorage.getItem(STORAGE_ITEM.USER_INFO) || '{}')
      : {},
  pathnameHistory: '',
}

export const adminLoginSlice = createSlice({
  name: 'adminLogin',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loginAdminSuccess: (state, action) => {
      state.isAdminLoggedIn = true
      state.adminInfo = action.payload.adminInfo

      if (action.payload.remember) {
        Cookies.set(
          COOKIE_ITEM.ADMIN_TOKEN,
          action.payload.adminInfo?.accessToken || '',
          { expires: 730 },
        )
      } else {
        Cookies.set(
          COOKIE_ITEM.ADMIN_TOKEN,
          action.payload.adminInfo?.accessToken || '',
        )
      }

      localStorage.setItem(
        STORAGE_ITEM.USER_INFO,
        JSON.stringify({
          ...action.payload.adminInfo,
          accessToken: undefined,
        }),
      )
    },
    logoutAdmin: (state) => {
      state.isAdminLoggedIn = false
      state.adminInfo = {}

      Cookies.remove(COOKIE_ITEM.ADMIN_TOKEN)
      localStorage.removeItem(STORAGE_ITEM.USER_INFO)
    },
    setPathNameHistory: (state, action) => {
      state.pathnameHistory = action.payload
    },
  },
})

export const { loginAdminSuccess, logoutAdmin, setPathNameHistory } =
  adminLoginSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectIsAdminLoggedIn = (state: RootState) =>
  state.adminLogin.isAdminLoggedIn

export const selectadminInfo = (state: RootState) => state.adminLogin.adminInfo
export const selectPathNameHisoty = (state: RootState) =>
  state.adminLogin.pathnameHistory

export default adminLoginSlice.reducer
