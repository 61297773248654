import { onError } from '@apollo/client/link/error'
import { Notification } from 'src/components/Notification'

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
      Notification({
        type: 'error',
        description: message,
      })

      setTimeout(() => {
        if (extensions?.response?.statusCode === 401) {
          window.location.href = '/admin'
        }
      }, 1000)
    })
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})
